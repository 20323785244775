import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";


export default class extends Controller {
  static targets = ["menu"]

  clickedImageId = ""
  clickedImageUrl = ""
  imageContextMenuDiv = ""
  imageContextMenuTurboFrame = ""
  mouseX = 0
  mouseY = 0
  rotateImageId = ""
  rotateImageDiv = ""

  connect() {
    this.hideMenu = this.hideMenu.bind(this)
    document.addEventListener("wheel", this.hideMenu)

    this.hideMenuCheck = this.hideMenuCheck.bind(this)
    document.addEventListener("click", this.hideMenuCheck)

    this.handleEscape = this.handleEscape.bind(this)
    document.addEventListener('keydown', this.handleEscape)

    this.recordMousePosition = this.recordMousePosition.bind(this)
    document.addEventListener('mousemove', this.recordMousePosition)
  }

  recordMousePosition(event) {
    this.currentMousePositionX = event.clientX
    this.currentMousePositionY = event.clientY
  }

  disconnect() {
    document.removeEventListener("scroll",    this.hideMenu)
    document.removeEventListener("click",     this.hideMenuCheck)
    document.removeEventListener('keydown',   this.handleEscape)
    document.removeEventListener('mousemove', this.recordMousePosition)
  }

  close() {
    this.menuTarget.classList.add("hidden")
  }

  open(event) {
    event.preventDefault()
    event.stopPropagation()

    let clickedElement = event.currentTarget
    this.clickedImageId = clickedElement.dataset.imagemenuImageIdValue
    this.clickedImageUrl = clickedElement.dataset.imagemenuImageUrlValue
    this.imageContextMenuDiv        = document.querySelector('#image_context_menu')
    this.imageContextMenuTurboFrame = document.querySelector('#image_context_menu turbo-frame')

    if (this.clickedImageId) {
      this.triggerImageContextMenuTurboFrame(event)
    } else {
      this.hideMenuOptions()
    }
  }

  menuTargetConnected(event) {
    this.showMenuOptions()
    this.closeOtherMenus()
    this.positionMenu(event)
    this.menuTarget.classList.remove("hidden")
  }

  hideMenuOptions() {
    this.toggleMenuOptions(true)
  }

  closeOtherMenus() {
    document.querySelectorAll("#context-menu").forEach((element) => {
      if (element !== this.menuTarget) {
        element.classList.add("hidden")
      }
    })
  }

  showMenuOptions() {
    this.toggleMenuOptions(false)
  }

  triggerImageContextMenuTurboFrame(event) {
    this.imageContextMenuDiv.setAttribute('data-mouseX', event.clientX)
    this.imageContextMenuDiv.setAttribute('data-mouseY', event.clientY)
    const newSrc = `/images/${this.clickedImageId}/context_menu.turbo_stream`
    this.imageContextMenuTurboFrame.setAttribute('id', `image_${this.clickedImageId}_context_menu`)
    this.imageContextMenuTurboFrame.setAttribute('src', newSrc)
  }

  toggleMenuOptions(hide) {
  }

  positionMenu() {
    this.mouseX = parseInt(this.imageContextMenuDiv.getAttribute('data-mouseX'))
    this.mouseY = parseInt(this.imageContextMenuDiv.getAttribute('data-mouseY'))
    let menuDimensions = this.getDimensions(this.menuTarget)
    this.menuTarget.style.left = `${this.clampValue(
      this.mouseX,
      window.innerWidth,
      menuDimensions.width
    )}px`
    this.menuTarget.style.top = `${this.clampValue(
      this.mouseY,
      window.innerHeight,
      menuDimensions.height
    )}px`
  }

  clampValue(value, maxValue, elementDimensions) {
    let viewportDimension = maxValue - elementDimensions
    return value > viewportDimension ? viewportDimension : value
  }

  getDimensions(element) {
    let dimensions = {}
    element.classList.remove("hidden")
    dimensions.width = element.offsetWidth
    dimensions.height = element.offsetHeight
    element.classList.add("hidden")
    return dimensions
  }

  hideMenuCheck(event) {
    if (this.shouldHideMenu(event)) {
      this.hideMenu()
    }
  }

  hideMenu() {
    if (this.hasMenuTarget) {
      this.menuTarget.classList.add("hidden")
    }
  }

  handleEscape(event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
      // Your action when Escape is pressed, e.g., hide the context menu:
      this.hideMenuCheck(event)
    }
  }

  shouldHideMenu(event) {
    if (!this.hasMenuTarget) {
      return false
    }
    return (
      !this.element.contains(event.target) ||
      event.target === this.menuTarget ||
      event.target.closest("a")
    )
  }

  rotate(event) {
    this.rotateImageId = event.currentTarget.dataset.rotateimageid
    // target where dom id is image_801443
    this.rotateImageDiv = document.querySelector('#image_' + this.rotateImageId);
    this.current_rotation = Number(this.rotateImageDiv.dataset.rotate);
    this.setImageRotation();
  }

  setImageRotation() {
    let new_rotation = this.current_rotation + 90;
    if(new_rotation > 270){
      new_rotation = 0;
    };
    this.rotateImageDiv.dataset.rotate = new_rotation;
    this.rotateImageDiv.style.transform = "rotate(" + (new_rotation) + "deg)";
    this.saveRotate();
  }

  saveRotate() {
    fetch('/images/' + this.rotateImageId + '/rotate' + '?rotate=' + this.rotateImageDiv.dataset.rotate, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    });
  }
}
