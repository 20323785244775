import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="maps"
export default class extends Controller {
  static targets = ["field", "map", "latitude", "longitude"]
  static values = { latitude: Number, longitude: Number }

  connect() {
    if (typeof (google) != "undefined") {
      this.initMap()
    }
  }

  initMap() {
    const coordinates = { lat: this.latitudeValue || -27.4367841, lng: this.longitudeValue || 153.0241567}
    this.map = new google.maps.Map(this.mapTarget, {
      center: coordinates,
      zoom: 15,
      gestureHandling: "greedy"
    })
    
    this.marker = new google.maps.Marker({
      position: coordinates,
      map: this.map
    })
  }
}
