import { Controller } from "@hotwired/stimulus"
import _ from "lodash"

// Connects to data-controller="directions"
export default class extends Controller {
  static targets = ["map", "panel"]
  static values = { waypoints: Array, origin: Array, destination: Array, departureTime: String }

  connect() {
    if (typeof (google) != "undefined") {
      this.initMap()
    }
  }

  initMap() {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();
    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 20,
      center: { lat: this.originValue[0], lng: this.originValue[1] },
      gestureHandling: "cooperative"
    });
    this.info = new google.maps.InfoWindow({
      content: "Shift Route"
    });

    directionsRenderer.setMap(this.map);
    directionsRenderer.setOptions({
      draggable: false,
      InfoWindow: this.info
    });
    this.calculateAndDisplayRoute(directionsService, directionsRenderer);
  }
  
  calculateAndDisplayRoute(directionsService, directionsRenderer) {
    this.waypts = [];

    if (this.waypointsValue) {
      this.waypointsValue.forEach((x, i) => {
        this.waypts.push({
          location: { lat: x[0], lng: x[1] },
          stopover: true
        });
      });
    }

    directionsService
      .route({
        origin: { lat: this.originValue[0], lng: this.originValue[1] },
        destination: { lat: this.destinationValue[0], lng: this.destinationValue[1] },
        waypoints: this.waypts,
        optimizeWaypoints: false,
        travelMode: google.maps.TravelMode.DRIVING,
        // drivingOptions: {
        //   departureTime: new Date(this.departureTimeValue.getTime()),
        //   trafficModel: TrafficModel
        // }
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
  
        const route = response.routes[0];
        const summaryPanel = this.panelTarget;
        
        this.totalDistance = 0.0
        summaryPanel.innerHTML = "";
        // For each route, display summary information.
        for (let i = 0; i < route.legs.length; i++) { this.totalDistance += route.legs[i].distance.value; }
        summaryPanel.innerHTML += "<div class=''>Total: " + _.round((this.totalDistance / 1000),2) + " kms</div>" ;
      })
      .catch((error) => console.error("Directions request failed due to " + error));
  }
}
