// app/javascript/controllers/clock_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.updateTime();
    this.startClock();
  }

  updateTime() {
    const clockElement = this.element;
    const now = new Date();
    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format and handle single-digit hours
    hours = hours % 12;
    hours = hours === 0 ? 12 : hours;
    const formattedHours = String(hours);

    clockElement.innerText = `${formattedHours}:${minutes}:${seconds} ${ampm}`;
  }

  startClock() {
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000); // Update every 1000 milliseconds (1 second)
  }

  disconnect() {
    clearInterval(this.timer);
  }
}
