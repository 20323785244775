import { Modal } from "tailwindcss-stimulus-components"

export default class Imagemodal extends Modal {
  static targets = ["container", "frame"]

  clickedImageId = ""
  imageDiv = ""
  imageTurboFrame = ""

  connect() {
    super.connect()
  }

  open(event) {
    super.open(event)
    
    let clickedElement = event.currentTarget
    this.clickedImageId = clickedElement.dataset.imagemodalImageIdValue
    this.imageDiv       = document.querySelector('#image')
    this.imageTurboFrame = document.querySelector('#image turbo-frame')

    if (this.clickedImageId) {
      this.triggerImageTurboFrame(event)
    } else {
      doNothing()
    }
  }

  closeBackground(event) {
    super.closeBackground(event)
  }

  close(event) {
    super.close(event)
  }

  triggerImageTurboFrame(event) {
    const newSrc = `/images/${this.clickedImageId}/modal.turbo_stream`
    this.imageTurboFrame.setAttribute('src', newSrc)
  }
}