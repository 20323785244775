import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    "clientSelect", "campaignSelect", 
    "productbrandSelect", "productkindSelect", "productassortmentSelect", "productsizeSelect", "productSelect",
    "retailerSelect", "categorySelect", "retailerbannerSelect", "placeSelect", "placegroupSelect",
    "periodSelect", "componenttypeSelect", "componentidSelect",
  ]

  initialize() {
  }

  connect() {
  }

  submit() {
  }

  periodSelected() {
    let period = this.periodSelectTarget.value
    let client = this.clientSelectTarget.value
    
    try {
      let campaign = this.campaignSelectTarget.id
      get(`/forms/client/campaigns?target=${ campaign }&client_id=${ client }&reporting_period_id=${ period }`, {
        responseKind: "turbo-stream"
        }
      )
    } catch( exception ) {
      console.log('Campaign Target does not exist.')
    }
  }

  // SINGLE SELECT ACTIONS
  clientSelected() {
    let client = this.clientSelectTarget.value
    
    try {
      let period = this.periodSelectTarget.value
    } catch( exception ) {
      console.log('Period Target does not exist.')
    }
    
    try {
      let campaign = this.campaignSelectTarget.id
      get(`/forms/client/campaigns?target=${ campaign }&client_id=${ client }&reporting_period_id=${ period }`, {
        responseKind: "turbo-stream"
        }
      )
    } catch( exception ) {
      console.log('Campaign Target does not exist.')
    }

    try {
      let product_brand = this.productbrandSelectTarget.id;
      get(`/forms/client/product_brands?target=${ product_brand }&client_id=${ client }`, {
        responseKind: "turbo-stream"
        }
      )
    } catch( exception ) {
      console.log('Product Brand Target does not exist.')
    }

    try {
      let product_kind = this.productkindSelectTarget.id;
      get(`/forms/client/product_kinds?target=${ product_kind }&client_id=${ client }`, {
        responseKind: "turbo-stream"
        }
      )
    } catch( exception ) {
      console.log('Product Kind Target does not exist.')
    }
    
    try {
      let product_assortment = this.productassortmentSelectTarget.id;
      get(`/forms/client/product_assortments?target=${ product_assortment }&client_id=${ client }`, {
        responseKind: "turbo-stream"
        }
      )
    } catch( exception ) {
      console.log('Product Assortment Target does not exist.')
    }
    
    try {
      let product = this.productSelectTarget.id;
      get(`/forms/client/products?target=${ product }&client_id=${ client }`, {
        responseKind: "turbo-stream"
        }
      )
    } catch( exception ) {
      console.log('Product Assortment Target does not exist.')
    }
    
    try {
      let retailer = this.retailerSelectTarget.id;
      get(`/forms/client/retailers?target=${ retailer }&client_id=${ client }`, {
        responseKind: "turbo-stream"
        }
      )
    } catch( exception ) {
      console.log('Retailers Target does not exist.')
    }
    
    try {
      let category = this.categorySelectTarget.id;
      get(`/forms/client/categories?target=${ category }&client_id=${ client }`, {
        responseKind: "turbo-stream"
        }
      )
    } catch( exception ) {
      console.log('Categories Target does not exist.')
    }
    
    try {
      let placegroup = this.placegroupSelectTarget.id;
      get(`/forms/client/place_groups?target=${ placegroup }&client_id=${ client }`, {
        responseKind: "turbo-stream"
        }
      )
    } catch( exception ) {
      console.log('Place Groups Target does not exist.')
    }
  }

  // MULTI SELECT ACTIONS
  productbrandsSelected() {
    let client = this.clientSelectTarget.value
    let product_brands = [...this.productbrandSelectTarget.options].filter(x => x.selected).map(x => x.value)
    
    let product_kind = this.productkindSelectTarget.id;
    get(`/forms/client/product_brand/product_kinds?target=${ product_kind }&product_brands=${ JSON.stringify(product_brands) }&client_id=${ client }`, {
      responseKind: "turbo-stream"
      }
    )
    
    if (this.hasproductSelectTarget) {
      let product = this.productSelectTarget.id;
      get(`/forms/client/product_brand/products?target=${ product }&product_brands=${ JSON.stringify(product_brands) }&client_id=${ client }`, {
        responseKind: "turbo-stream"
        }
      )
    }
  }
  productkindsSelected() {
    let client = this.clientSelectTarget.value
    let product_kinds = [...this.productkindSelectTarget.options].filter(x => x.selected).map(x => x.value)
    
    let product_assortment = this.productassortmentSelectTarget.id;
    get(`/forms/client/product_kind/product_assortments?target=${ product_assortment }&product_kinds=${ JSON.stringify(product_kinds) }&client_id=${ client }`, {
      responseKind: "turbo-stream"
      }
    )
    
    if (this.hasproductSelectTarget) {
      let product = this.productSelectTarget.id;
      get(`/forms/client/product_kind/products?target=${ product }&product_kinds=${ JSON.stringify(product_kinds) }&client_id=${ client }`, {
        responseKind: "turbo-stream"
        }
      )
    }
  }

  productassortmentsSelected() {
    let client = this.clientSelectTarget.value
    let product_assortments = [...this.productassortmentSelectTarget.options].filter(x => x.selected).map(x => x.value)
    
    if (this.hasproductSelectTarget) {
      let product = this.productSelectTarget.id;
      get(`/forms/client/product_assortment/products?target=${ product }&product_assortments=${ JSON.stringify(product_assortments) }&client_id=${ client }`, {
        responseKind: "turbo-stream"
        }
      )
    }
  }

  productsizesSelected() {
  }

  retailersSelected() {
    let client = this.clientSelectTarget.value
    let retailers = [...this.retailerSelectTarget.options].filter(x => x.selected).map(x => x.value)
    
    let retailer_banner = this.retailerbannerSelectTarget.id;
    get(`/forms/client/retailer/retailer_banners?target=${ retailer_banner }&retailers=${ JSON.stringify(retailers) }&client_id=${ client }`, {
      responseKind: "turbo-stream"
      }
    )
  }

  categoriesSelected() {
    let client = this.clientSelectTarget.value
    let categories = [...this.categorySelectTarget.options].filter(x => x.selected).map(x => x.value)
    
    let retailer_banner = this.retailerbannerSelectTarget.id;
    get(`/forms/client/category/retailer_banners?target=${ retailer_banner }&categories=${ JSON.stringify(categories) }&client_id=${ client }`, {
      responseKind: "turbo-stream"
      }
    )
  }

  retailerbannersSelected() {
    let retailer_banners = [...this.retailerbannerSelectTarget.options].filter(x => x.selected).map(x => x.value)
    
    let place = this.placeSelectTarget.id;
    get(`/forms/client/retailer_banner/places?target=${ place }&retailer_banners=${ JSON.stringify(retailer_banners) }`, {
      responseKind: "turbo-stream"
      }
    )
    
  }

  componenttypeSelected() {
    let client = this.clientSelectTarget.value
    let componenttype = this.componenttypeSelectTarget.value
    let target = this.componentidSelectTarget.id
    
    try {
      get(`/forms/deckslide_components/component_types?target=${ target }&component_type=${ JSON.stringify(componenttype) }&client_id=${ client }`, {
        responseKind: "turbo-stream"
        }
      )
    } catch( exception ) {
      console.log('Component Type Target does not exist.')
    }
  }
}