import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["chart", "ping", "card"]
  static values = { id: String, visual: String, refreshInterval: String, textColour: String, fontSize: String, showDatasetLabels: String, datasetLabelFormat: String }

  connect() {
    this.insertChart()
  }

  insertChart() {
    if (!this.currentChart) {
      if (this.visualValue == 'line') {
        this.currentChart = this.lineChart()
      } else if (this.visualValue == 'column') {
        this.currentChart = this.columnChart()
      } else if (this.visualValue == 'bar') {
        this.currentChart = this.barChart()
      } else if (this.visualValue == 'pie') {
        this.currentChart = this.pieChart()
      } else if (this.visualValue == 'card') {
        this.startCardRefresh()
      } else if (this.visualValue == 'table') {
        this.startTableRefresh()
      } else {
        console.log('No chart type specified')
      }
    }
  }

  startCardRefresh() {
    this.refreshInterval = setInterval(() => {
      fetch(`/goals/${this.idValue}/chart.json`)
        .then(response => response.json())
        .then(data => {
          this.updateCardData(data);
        });
    }, this.refreshIntervalValue * 100); // 10 seconds
  }

  startTableRefresh() {
    this.refreshInterval = setInterval(() => {
      fetch(`/goals/${this.idValue}/chart.json`)
        .then(response => response.json())
        .then(data => {
          this.updateTableData(data);
        });
    }, this.refreshIntervalValue * 100); // 10 seconds
  }

  updateCardData(data) {
    // Assuming data is a simple number, or string representation of the number
    // If it's more complex, you may need to parse/transform it appropriately
    this.cardTarget.innerHTML = data.value;
  }

  updateTableData(data) {
    // Assuming data is a simple number, or string representation of the number
    // If it's more complex, you may need to parse/transform it appropriately
    return
  }

  disconnect() {
    // Clear the refresh interval when the Stimulus controller is disconnected
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  lineChart() {
    const chart = new Chartkick.LineChart(
      this.chartTarget,
      `/goals/${this.idValue}/chart.json`,
      {
        responsive: true,
        refresh: this.refreshIntervalValue,
        download: true,
        opacity: 1.0,
        colors: this.textColourValue.split(','),
        dataset: {
          showLine: true,
          pointStyle: 'circle'
        },
        library: {
          scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                autoSkip: false,
                display: true,
                color: "#005A90",
                font: {
                  size: this.fontSizeValue
                },
                padding: 8
              },
            },
            y: {
              grid: {
                display: false
              },
              border: {
                display: false
              },
              ticks: {
                display: false
              },
            }
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                // Determine the label of the current slice
                const label = ctx.chart.data.labels[ctx.dataIndex];

                // Calculate the percentage
                const datapoints = ctx.chart.data.datasets[0].data;
                const total = datapoints.reduce((total, datapoint) => total + datapoint, 0);
                const percentage = value / total * 100;

                return this.setDataLabels(label, value, percentage);
              },
              display: true,
              color: '#fcaf17',
              anchor: 'center',
              clamp: true,
              clip: false,
              align: 'bottom',
              font: {
                size: this.fontSizeValue,
                weight: 'bolder'
              }
            }
          }
        }
      }
    )
    return chart
  }

  columnChart() {
    const chart = new Chartkick.ColumnChart(
      this.chartTarget,
      `/goals/${this.idValue}/chart.json`,
      {
        responsive: true,
        refresh: this.refreshIntervalValue,
        download: true,
        opacity: 1.0,
        colors: this.textColourValue.split(','),
        dataset: {
          borderWidth: 0,
          borderColor: '#003d68',
          hoverBorderColor: '#003d68',
          hoverBorderWidth: 2
        },
        library: {
          scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                display: true,
                autoSkip: false,
                color: '#005A90',
                font: {
                  size: this.fontSizeValue
                },
                padding: 8
              },
            },
            y: {
              grid: {
                display: false
              },
              border: {
                display: false
              },
              ticks: {
                autoSkip: false,
                display: false
              },
              borderWidth: 0
            }
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                // Determine the label of the current slice
                const label = ctx.chart.data.labels[ctx.dataIndex];

                // Calculate the percentage
                const datapoints = ctx.chart.data.datasets[0].data;
                const total = datapoints.reduce((total, datapoint) => total + datapoint, 0);
                const percentage = value / total * 100;

                return this.setDataLabels(label, value, percentage);
              },
              display: true,
              color: '#003d68',
              anchor: 'end',
              clamp: true,
              clip: false,
              textAlign: 'center',
              align: 'end',
              font: {
                size: this.fontSizeValue,
                weight: 'bolder'
              }
            },
            animation: { easing: 'easeInOutQuart' },
          }
        }
      }
    )
    return chart
  }

  barChart() {
    const chart = new Chartkick.BarChart(
      this.chartTarget,
      `/goals/${this.idValue}/chart.json`,
      {
        responsive: true,
        refresh: this.refreshIntervalValue,
        download: true,
        opacity: 1.0,
        colors: this.textColourValue.split(','),
        dataset: {
          borderWidth: 0,
          borderColor: '#003d68',
          hoverBorderColor: '#003d68',
          hoverBorderWidth: 2
        },
        library: {
          scales: {
            x: {
              title: {
                display: false,
              },
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
              ticks: {
                display: false,
              }
            },
            y: {
              title: {
                display: false,
              },
              grid: {
                display: false,
              },
              ticks: {
                display: true,
                autoSkip: false,
                color: '#005A90',
                font: {
                  size: this.fontSizeValue
                },
                padding: 8
              },
            }
          },
          plugins: {
            legend: { display: false },
            datalabels: {
              formatter: (value, ctx) => {
                // Determine the label of the current slice
                const label = ctx.chart.data.labels[ctx.dataIndex];

                // Calculate the percentage
                const datapoints = ctx.chart.data.datasets[0].data;
                const total = datapoints.reduce((total, datapoint) => total + datapoint, 0);
                const percentage = value / total * 100;

                return this.setDataLabels(label, value, percentage);
              },
              display: true,
              color: '#282C34',
              anchor: 'end',
              clamp: true,
              clip: false,
              textAlign: 'center',
              align: 'end',
              font: {
                size: this.fontSizeValue,
                weight: 'bolder'
              }
            }
          }
        }
      }
    )
    return chart
  }

  pieChart() {
    const chart = new Chartkick.PieChart(
      this.chartTarget,
      `/goals/${this.idValue}/chart.json`,
      {
        responsive: true,
        refresh: this.refreshIntervalValue,
        download: true,
        opacity: 0.5,
        donut: true,
        legend: "bottom",
        colors: this.textColourValue.split(','),
        dataset: {
          borderWidth: 2,
          borderColor: "#FFFFFF",
          hoverBorderColor: "#FFFFFF",
          hoverBorderWidth: 2
        },
        library: {
          hoverOffset: 4,
          segmentStrokeWidth: 20,
          segmentStrokeColor: "#FFFFFF",
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                // Determine the label of the current slice
                const label = ctx.chart.data.labels[ctx.dataIndex];

                // Calculate the percentage
                const datapoints = ctx.chart.data.datasets[0].data;
                const total = datapoints.reduce((total, datapoint) => total + datapoint, 0);
                const percentage = value / total * 100;

                return this.setDataLabels(label, value, percentage);
              },
              display: true,
              color: '#FFFFFF',
              anchor: 'center',
              clamp: true,
              clip: false,
              align: 'center',
              textAlign: 'center',
              font: {
                size: this.fontSizeValue,
                weight: 'bolder'
              }
            }
          }
        }
      }
    )
    return chart
  }

  setDataLabels(label, value, percentage) {
    if (this.showDatasetLabelsValue == 'true') {
      if (this.datasetLabelFormatValue == 'value') {
        return value;
      } else if (this.datasetLabelFormatValue == 'percentage') {
        return percentage.toFixed(1) + "%";
      } else if (this.datasetLabelFormatValue == 'label') {
        return label;
      } else if (this.datasetLabelFormatValue == 'value_and_percentage') {
        return value + '\n' + percentage.toFixed(1) + "%";
      } else if (this.datasetLabelFormatValue == 'label_and_value') {
        return label + '\n' + value;
      } else if (this.datasetLabelFormatValue == 'label_and_percentage') {
        return label + '\n' + percentage.toFixed(1) + "%";
      } else if (this.datasetLabelFormatValue == 'label_and_value_and_percentage') {
        return label + '\n' + value + '\n' + percentage.toFixed(1) + "%";
      } else {
        return value;
      }
    } else {
      return "";
    }
  }
}
