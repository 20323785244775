import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// Connects to data-controller="zoom"
export default class extends Controller {
  static targets = [ "img", "imgContainer" ]

  scale = 0.90;
  max_zoom_out_scale = 1.00;
  max_zoom_in_scale = 7.00;
  panning = false;
  pointX = 0;
  pointY = 0;
  imgRight = 0;
  imgY = 0;
  imgContainerX = 0;
  imgContainerY = 0;
  start = { x: 0, y: 0}

  // Add correction factors
  correctionXIn  =  24;   // Initialize a correction factor for X
  correctionYIn  = -12; // Initialize a correction factor for Y
  correctionXOut = 0;   // Initialize a correction factor for X
  correctionYOut = 30; // Initialize a correction factor for Y
  correctionX    = 0;   // Initialize a correction factor for X
  correctionY    = 0;   // Initialize a correction factor for Y

  connect() {
  }

  resetImage(e) {
    this.pointX = 0;
    this.pointY = 0;
    this.scale = 1;
    this.onMouseUp(e);
    this.setTransform();
  }

  // Throttle function
  throttle(func, limit) {
    let inThrottle;
    return function() {
      const args = arguments;
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => inThrottle = false, limit);
      }
    };
  }

  onMouseLeave(e) {
    this.panning = false;
    this.onMouseUp(e);
  }

  onMouseMove(e) {
    e.preventDefault();
    if (!this.panning) {
      return;
    }
    const imgContainer = this.imgContainerTarget.getBoundingClientRect();
    const imgContainerLeft = imgContainer.left;
    const imgContainerRight = imgContainer.right;
    const imgContainerTop = imgContainer.top;
    const imgContainerBottom = imgContainer.bottom;
    const img = this.imgTarget.getBoundingClientRect();
    const imgLeft = img.left;
    const imgRight = img.right;
    const imgTop = img.top;
    const imgBottom = img.bottom;
    const mouse = { x: e.clientX, y: e.clientY };
    const xs = (e.clientX - this.pointX) / this.scale;
    const ys = (e.clientY - this.pointY) / this.scale;
  
    // The image can go off the screen but don't let it do it by less than 100px of the imgContainer X and Y
    if ((imgRight - 100) < imgContainerLeft) {
      return;
    }

    if ((imgBottom - 100) < imgContainerTop) {
      return;
    }

    this.pointX = (mouse.x - this.start.x);
    this.pointY = (mouse.y - this.start.y);

    this.setTransform();
  }

  onMouseUp(e) {
    this.panning = false;
    if (e.button === 0) {
      this.imgTarget.classList.add('hover:cursor-grab');
      this.imgTarget.classList.remove('hover:cursor-grabbing');
    }
  }

  onMouseDown(e) {
    if (e.button === 0) {
      e.preventDefault();
      this.start = { x: e.clientX - this.pointX, y: e.clientY - this.pointY };
      this.panning = true;
      
      this.imgTarget.classList.remove('hover:cursor-grab');
      this.imgTarget.classList.add('hover:cursor-grabbing');
    }
  }

  onWheel(e) {
    e.preventDefault();
    this.throttledZoom(e);
  }

  throttledZoom = this.throttle(function(e) {
    const xs = (e.clientX - this.pointX) / this.scale;
    const ys = (e.clientY - this.pointY) / this.scale;
    const delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY);

    // only continue if the scale is more than the max zoom out scale
    if (this.scale <= this.max_zoom_out_scale && delta < 0) {
      this.scale = this.max_zoom_out_scale;
      this.setTransform();
      this.resetImage();
      return;
    }

    // only continue if the scale is less than the max zoom in scale
    if (this.scale >= this.max_zoom_in_scale && delta > 0) {
      return;
    }

    (delta > 0) ? (this.scale *= 1.2) : (this.scale /= 1.2);

    this.correctionX = (delta > 0) ? this.correctionXIn : this.correctionXOut;
    this.correctionY = (delta > 0) ? this.correctionYIn : this.correctionYOut;

    this.pointX = e.clientX - (xs * this.scale) + (delta + this.correctionX);
    this.pointY = e.clientY - (ys * this.scale) + (delta + this.correctionY);
    this.setTransform();
  }, 25);

  setTransform() {
    const controlled_scale = Math.max(this.max_zoom_out_scale, this.scale)
    this.scale = controlled_scale
    this.current_rotation = Number(this.imgTarget.dataset.rotate);
    this.imgTarget.style.transform = "translate(" + (this.pointX) + "px, " + (this.pointY) + "px) scale(" + this.scale + ") rotate(" + (this.current_rotation) + "deg)";
  }

  in(){
    this.scale = this.scale + 1.00;
    this.setTransform();
  }

  out() {
    this.scale = this.scale - 1.00;
    this.setTransform();
  }

  rotate() {
    this.current_rotation = Number(this.imgTarget.dataset.rotate);
    this.setImageRotation();
  }

  setImageRotation() {
    let new_rotation = this.current_rotation + 90;
    if(new_rotation > 270){
      new_rotation = 0;
    };
    this.imgTarget.dataset.rotate = new_rotation;
    this.saveRotate();
    this.setTransform();
  }

  saveRotate() {
    fetch('/images/' + this.imgTarget.dataset.id + '/rotate' + '?rotate=' + this.imgTarget.dataset.rotate, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    });
  }
}
