import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = [ 
    "container", "field", "selections", "comboInput", "results", "dropdown", 
    "filter", "distinct", "joinTables", "tableName", "frameId", 
    "searchTableName", "searchFieldName", "conditions", "minChar",
    "findBy", "selectionIds"
  ]
  static values = {  }

  bentoId = '';
  bento   = '';
  dropdownId = '';

  initialize() {
    this.submit = debounce(this.submit.bind(this), 200)
  }

  connect() {
    this.delayTimeout = null
    console.log("Bento controller connected");
    this.comboInputTarget.addEventListener('input', this.handleComboInput.bind(this));

    // Add hover listener to the entire bento-dropdown div
    this.addDropdownHoverListener();

    // add listener to detect clicks outside the dropdown
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener('click', this.handleClickOutside);

    this.bentoId    = this.containerTarget.id;
    this.bento      = document.querySelector(`#${this.bentoId}`);
    this.dropdown   = document.querySelector(`#${this.bentoId}-dropdown`);
  }
  
  disconnect(event) {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const containerElement = this.containerTarget;
    const comboInputElement    = this.comboInputTarget;
    const dropdownElement = this.dropdown;

    if (!containerElement.contains(event.target) && !comboInputElement.contains(event.target) && !dropdownElement.contains(event.currentTarget.activeElement)) {
      // Click is outside the comboInput and dropdown
      this.closeDropdown();
    }
  }

  handleComboInput(event) {
    console.log("Input changed:", event.target.value);
    if (event.target.value === '') {
      this.submitAsTurboStream();
    }
  }

  addDropdownHoverListener() {
    const dropdownElement = this.element.querySelector(`#${ this.bentoId }-dropdown`);
  
    if (dropdownElement) {
      dropdownElement.addEventListener('mouseover', (event) => {
        if (event.target.closest(`div#${ this.bentoId }-dropdown`).contains(event.target)) {
          this.highlightSelectedValues();
        }
      });
    }
  }

  openDropdown() {
    this.dropdown.classList.remove("hidden");
    this.comboInputTarget.focus();
    this.submitAsTurboStream();
  }

  closeDropdown(event) {
    const dropdown = document.querySelector(`div#${ this.bentoId }-dropdown`);
    dropdown.classList.add("hidden");
  }

  keyPress(event) {
    clearTimeout(this.delayTimeout)
    this.delayTimeout = setTimeout(() => {
      if (event.key !== "Enter") {
        event.preventDefault();

        if (this.comboInputTarget.value.length < this.minCharTarget.textContent) {
          return;
        }

        this.submitAsTurboStream();
      }
    }, 300)
  }

  getSelectedOption(event) {
    const selectedValue = event.currentTarget.dataset.value;
    const selectedId    = event.currentTarget.dataset.index;

    // Check if a pill with the selectedValue already exists
    const existingPill = Array.from(this.selectionsTarget.children).find(
      (child) => child.textContent.replace('×', '').trim() === selectedValue
    );
  
    if (existingPill) {
      return;
    }

    this.selectionsTarget.value = selectedValue;

    const pill = document.createElement('span');
    pill.className = "inline-flex items-center hover:cursor-auto px-2 py-1 bg-blue-500 text-white text-sm font-medium rounded-md mr-1";
    // Add the Stimulus data action attribute
    pill.setAttribute('data-index', selectedId);
    pill.setAttribute('data-action', 'click->bento#removePill');
    
    pill.textContent = selectedValue;

    const closeButton = document.createElement('button');
    closeButton.className = "border-l border-white hover:cursor-pointer pl-1 ml-1 text-white text-sm focus:outline-none";
    closeButton.innerHTML = "&times;";

    pill.appendChild(closeButton);
    this.selectionsTarget.appendChild(pill);
  
    // Add the selected ID to the hidden field
    this.updateSelectionIds(selectedId, true);

    event.currentTarget.classList.add("bg-blue-500", "text-white");
  }

  removePill(event) {
    const pill          = event.currentTarget;
    const selectedId    = pill.dataset.index;
    pill.remove();
    this.updateSelectionIds(selectedId, false);
    
    const equivalentOption = Array.from(document.querySelectorAll('[role="option"]')).find(
      (option) => option.dataset.index === selectedId
    );

    if (equivalentOption) {
      equivalentOption.classList.remove("bg-blue-500", "text-white");
    }
  }

  updateSelectionIds(id, add) {
    let comboInputField = this.selectionIdsTarget;
    let ids             = comboInputField.value.split(',').filter(Boolean);

    if (add) {
      if (!ids.includes(id)) {
        ids.push(id);
      }
    } else {
      ids = ids.filter(existingId => existingId !== id);
    }
    comboInputField.defaultValue = ids.join(',');
  }

  submit() {
    this.submitTarget.click()
  }
  
  hideValidationMessage(event) {
    event.stopPropagation()
    event.preventDefault()
  }

  log_all_target_values() {
    console.log("comboInputTarget value: ",       this.comboInputTarget.value);
    console.log("tableNameTarget value: ",        this.tableNameTarget.textContent);
    console.log("joinTablesTarget value: ",       this.joinTablesTarget.textContent);
    console.log("searchTableNameTarget value: ",  this.searchTableNameTarget.textContent);
    console.log("searchFieldNameTarget value: ",  this.searchFieldNameTarget.textContent);
    console.log("distinctTarget value: ",         this.distinctTarget.textContent);
    console.log("filterTarget value: ",           this.filterTarget.textContent);
    console.log("frameIdTarget value: ",          this.frameIdTarget.textContent);
    console.log("conditionsTarget value: ",       this.conditionsTarget.textContent);
    console.log("minCharTarget value: ",          this.minCharTarget.textContent);
    console.log("findBy value: ",                 this.findByTarget.textContent);
    console.log("selectionIdsTarget value: ",     this.selectionIdsTarget.textContent);
  }

  submitAsTurboStream() {
    const selectedValues = {
        query:                               this.comboInputTarget.value,
        selectionIds:                        this.selectionIdsTarget.textContent,
        clientSelectionIds:                  this.getClientSelectionsIds(),
        campaignSelectionIds:                this.getCampaignSelectionsIds(),
        reportingPeriodSelectionIds:         this.getReportingPeriodSelectionsIds(),
        campaignBrandSelections:             this.getCampaignBrandSelections(),
        campaignRetailerSelections:          this.getCampaignRetailerSelections(),
        campaignTaskImageTypeSelections:     this.getCampaignTaskImageTypeSelections(),
        placeSelectionIds:                   this.getPlaceSelectionsIds(),
        placeCategorySelections:             this.getPlaceCategorySelections(),
        placeRetailerBannerSelections:       this.getPlaceRetailerBannerSelections(),
        placeRetailerTypeSelections:         this.getPlaceRetailerTypeSelections(),
        tableName:                           this.tableNameTarget.textContent,
        joinTables:                          this.joinTablesTarget.textContent,
        searchTableName:                     this.searchTableNameTarget.textContent,
        searchFieldName:                     this.searchFieldNameTarget.textContent,
        distinct:                            this.distinctTarget.textContent,
        filter:                              this.filterTarget.textContent,
        frameId:                             this.frameIdTarget.textContent,
        conditions:                          this.conditionsTarget.textContent,
        findBy:                              this.findByTarget.textContent
    };

    if (this.formIsNotSubmittable(selectedValues)){
      return;
    }

    this.showLoadingIcon();

    const formData = new FormData();
    // this.log_all_target_values();
    if (selectedValues.query)                              formData.append("query",                                selectedValues.query);
    if (selectedValues.clientSelectionIds)                 formData.append("client_selection_ids",                 selectedValues.clientSelectionIds);
    if (selectedValues.campaignSelectionIds)               formData.append("campaign_selection_ids",               selectedValues.campaignSelectionIds);
    if (selectedValues.campaignBrandSelections)            formData.append("campaign_brand_selections",            selectedValues.campaignBrandSelections);
    if (selectedValues.campaignRetailerSelections)         formData.append("campaign_retailer_selections",         selectedValues.campaignRetailerSelections);
    if (selectedValues.campaignTaskImageTypeSelections)    formData.append("campaign_task_image_type_selections",  selectedValues.campaignTaskImageTypeSelections);
    if (selectedValues.reportingPeriodSelectionIds)        formData.append("reporting_period_selection_ids",       selectedValues.reportingPeriodSelectionIds);
    if (selectedValues.placeSelectionIds)                  formData.append("place_selection_ids",                  selectedValues.placeSelectionIds);
    if (selectedValues.placeCategorySelections)            formData.append("place_category_selections",            selectedValues.placeCategorySelections);
    if (selectedValues.placeRetailerBannerSelections)      formData.append("place_retailer_banner_selections",     selectedValues.placeRetailerBannerSelections);
    if (selectedValues.placeRetailerTypeSelections)        formData.append("place_retailer_type_selections",       selectedValues.placeRetailerTypeSelections);
    if (selectedValues.selectionIds)                       formData.append("selection_ids",                        selectedValues.selectionIds);
    if (selectedValues.tableName)                          formData.append("table_name",                           selectedValues.tableName);
    if (selectedValues.joinTables)                         formData.append("join_tables",                          selectedValues.joinTables);
    if (selectedValues.searchTableName)                    formData.append("search_table_name",                    selectedValues.searchTableName);
    if (selectedValues.searchFieldName)                    formData.append("search_field_name",                    selectedValues.searchFieldName);
    if (selectedValues.distinct)                           formData.append("distinct",                             selectedValues.distinct);
    if (selectedValues.filter)                             formData.append("filter",                               selectedValues.filter);
    if (selectedValues.frameId)                            formData.append("frame_id",                             selectedValues.frameId);
    if (selectedValues.conditions)                         formData.append("conditions",                           selectedValues.conditions);
    if (selectedValues.findBy)                             formData.append("find_by",                              selectedValues.findBy);

    fetch('/bento', {
      method: "POST",
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: formData
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html);
    })
    .then(() => {
      this.addDropdownHoverListener();
      this.highlightSelectedValues();
      this.hideLoadingIcon();
    })
  }

  showLoadingIcon() {
    let loadingIcon = this.selectionsTarget.querySelector('span');
    if (!loadingIcon) {
      loadingIcon = document.createElement('span');
      loadingIcon.classList.add("h-full", "text-red-500", "items-center");
      loadingIcon.textContent = "Loading...";
      this.selectionsTarget.classList.remove("bg-white");
      this.selectionsTarget.classList.add("bg-gray");
      this.selectionsTarget.appendChild(loadingIcon);
    }
  }

  hideLoadingIcon() {
    let loadingIcon = this.selectionsTarget.querySelector('span');
    if (loadingIcon && loadingIcon.textContent === "Loading...") {
      this.selectionsTarget.classList.remove("bg-gray");
      this.selectionsTarget.classList.add("bg-white");
      loadingIcon.remove();
    }
  }

  highlightSelectedValues() {
    const selectedIds = this.selectionIdsTarget.value.split(',').filter(Boolean);
  
    for (const selectedId of selectedIds) {
      const option = Array.from(document.querySelectorAll('[role="option"]')).find(
        (option) => {
          return option.dataset.index === selectedId; // Use `data-index` instead of `data-id`
        }
      );
      if (option) {
        option.classList.add("bg-blue-500", "text-white");
      }
    }
  }

  getClientSelectionsIds() {
    const div = document.querySelector(`#bento-clients-selection-ids`);
    if (!div) return [];

    const filteredValues = div.value.replace(/[^0-9,]/g, '');
    if (filteredValues === "") return [];

    const selectionIds = filteredValues.split(",").map(id => id.trim());
    return selectionIds;
  }

  getPlaceSelectionsIds() {
    const div = document.querySelector(`#bento-places-selection-ids`);
    if (!div) return [];

    const filteredValues = div.value.replace(/[^0-9,]/g, '');
    if (filteredValues === "") return [];

    const selectionIds = filteredValues.split(",").map(id => id.trim());
    return selectionIds;
  }

  getPlaceCategorySelections() {
    const div = document.querySelector(`#bento-place-categories-selection-ids`);
    if (!div) return [];

    const filteredValues = div.value.replace(/[^a-zA-Z,]/g, '');
    if (filteredValues === "") return [];

    const selectionIds = filteredValues.split(",").map(id => id.trim());
    return selectionIds;
  }

  getPlaceRetailerBannerSelections() {
    const div = document.querySelector(`#bento-place-retailer-banners-selection-ids`);
    if (!div) return [];

    const filteredValues = div.value;
    if (filteredValues === "") return [];

    const selectionIds = filteredValues.split(",").map(id => id.trim());
    return selectionIds;
  }

  getPlaceRetailerTypeSelections() {
    const div = document.querySelector(`#bento-place-retailer-types-selection-ids`);
    if (!div) return [];

    const filteredValues = div.value;
    if (filteredValues === "") return [];

    const selectionIds = filteredValues.split(",").map(id => id.trim());
    return selectionIds;
  }

  getReportingPeriodSelectionsIds() {
    const div = document.querySelector(`#bento-reporting-periods-selection-ids`);
    if (!div) return [];

    const filteredValues = div.value.replace(/[^0-9,]/g, '');
    if (filteredValues === "") return [];

    const selectionIds = filteredValues.split(",").map(id => id.trim());
    return selectionIds;
  }

  getCampaignSelectionsIds() {
    const div = document.querySelector(`#bento-campaigns-selection-ids`);
    if (!div) return [];

    const filteredValues = div.value.replace(/[^0-9,]/g, '');
    if (filteredValues === "") return [];

    const selectionIds = filteredValues.split(",").map(id => id.trim());
    return selectionIds;
  }

  getCampaignBrandSelections() {
    const div = document.querySelector(`#bento-campaign-brands-selection-ids`);
    if (!div) return [];

    const filteredValues = div.value;
    if (filteredValues === "") return [];

    const selectionIds = filteredValues.split(",").map(id => id.trim());
    return selectionIds;
  }

  getCampaignRetailerSelections() {
    const div = document.querySelector(`#bento-campaign-retailers-selection-ids`);
    if (!div) return [];

    const filteredValues = div.value;
    if (filteredValues === "") return [];

    const selectionIds = filteredValues.split(",").map(id => id.trim());
    return selectionIds;
  }

  getCampaignTaskImageTypeSelections() {
    const div = document.querySelector(`#bento-campaign-task-image-types-selection-ids`);
    if (!div) return [];

    const filteredValues = div.value;
    if (filteredValues === "") return [];

    const selectionIds = filteredValues.split(",").map(id => id.trim());
    return selectionIds;
  }

  getCampaignTaskImageTypeSelections() {
    const div = document.querySelector(`#bento-campaign-task-image-types-selection-ids`);
    if (!div) return [];

    const filteredValues = div.value.replace(/[^a-zA-Z,]/g, '');
    if (filteredValues === "") return [];

    const selectionIds = filteredValues.split(",").map(id => id.trim());
    return selectionIds;
  }

  formIsNotSubmittable(selectedValues) {
    if (selectedValues.clientSelectionIds && selectedValues.clientSelectionIds.length > 0) {
      return false;
    }
    if (this.comboInputTarget.value.length < this.minCharTarget.textContent) {
      return true;
    }
    if (this.selectionsTarget.textContent.includes('Loading...')) {
      return true;
    }
    this.delayTimeout = setTimeout(() => {
      return false;
    }, 1000)
  }
}
