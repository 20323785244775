import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "enhance", "tab", "image" ]

  connect() {
  }

  enhanceImage(event) {
    this.index = Number(event.currentTarget.dataset.index);
    this.showSelectedTab();
    this.showSelectedImage();
  }

  showSelectedTab(){
    this.tabTargets.forEach((tab, index) => {
      if (index === this.index) {
        tab.classList.add('active-image-tab')
      } else {
        tab.classList.remove('active-image-tab')
      }
    });
  }

  showSelectedImage(){
    this.imageTargets.forEach((image, index) => {
      if (index === this.index) {
        image.classList.remove('hidden')
      } else {
        image.classList.add('hidden')
      }
    });
  }
  
}
