import { Controller } from "@hotwired/stimulus"
import * as htmlToImage from 'html-to-image';
import FileSaver from 'file-saver';

export default class extends Controller {
  static targets = [ "album", "count", "albumid", "imageids", "showcheckboxes", "hidecheckboxes", "star"]

  initialize() {
  }

  connect() {
  }

  mark() {
    const element = this.albumTarget
    let checked_count = this.count()

    if (checked_count > 0) {
      element.classList.remove("hidden")
    } else {
      element.classList.add("hidden")
    }
    this.countTarget.innerHTML = checked_count
    this.imageidsTarget.value = this.selectedImageIds()
  }

  hidecheckboxes() {
    this.checkboxes().forEach(e => {
      e.classList.add("invisible")
    })
    this.hidecheckboxesTarget.classList.add("hidden")
    this.showcheckboxesTarget.classList.remove("hidden")
    // hide all the star targets
    this.starTargets.forEach(e => {
      e.classList.add("invisible")
    })
  }

  showcheckboxes() {
    this.checkboxes().forEach(e => {
      e.classList.remove("invisible")
    })
    this.showcheckboxesTarget.classList.add("hidden")
    this.hidecheckboxesTarget.classList.remove("hidden")
    // show all the star targets
    this.starTargets.forEach(e => {
      e.classList.remove("invisible")
    })
  }

  tickall() {
    this.checkboxes().forEach(e => {
      e.checked = true
    })
    this.mark()
  }

  untickall() {
    this.checkboxes().forEach(e => {
      e.checked = false
    })
    this.mark()
  }

  async snip() {
    this.hidecheckboxes();
    const capture = document.getElementById('capture')
    const page_id = document.getElementById('pagy').dataset.pageId;
    const url = window.location.pathname;
    const album_id = url.substring(url.lastIndexOf('/') + 1);
    const filename = `album-${ album_id }-${ page_id }-images-export-${ new Date().toLocaleTimeString() }.png`

    try {
      // Select all images on the page
      const images = document.querySelectorAll('img');
      let imagesLoaded = 0;

      // Function to call when all images have loaded
      const onAllImagesLoaded = async () => {
        const blob = await htmlToImage.toBlob(capture);
        if (window.saveAs) {
          window.saveAs(blob, filename);
        } else {
          FileSaver.saveAs(blob, filename);
        }
        this.showcheckboxes();
      };

      // Attach load event listener to each image
      images.forEach((img) => {
        img.addEventListener('load', () => {
          imagesLoaded++;
          if (imagesLoaded === images.length) {
            onAllImagesLoaded();
          }
        });
      });

      // In case images are cached and load event doesn't fire
      images.forEach((img) => {
        if (img.complete) {
          imagesLoaded++;
        }
      });
      if (imagesLoaded === images.length) {
        onAllImagesLoaded();
      }

    } catch (error) {
      console.error('oops, something went wrong!', error);
      console.log('Something went wrong with the snip tool!');
    }
  }

  selectedImageIds() {
    const ids = []
    this.checked().forEach(id => {
      ids.push(id.value)
    })
    return ids
  }

  checked() {
    return document.querySelectorAll('#album_images input[type="checkbox"]:checked')
  }

  count() {
    return this.checked().length
  }

  checkboxes() {
    return document.querySelectorAll('[name^="add_image_ids"]')
  }
}
