import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "editLink", "deleteLink"]
  static values = { componentId: String, componentType: String, deckslideComponentId: String }

  connect() {
    this.hideMenu = this.hideMenu.bind(this)
    document.addEventListener("click", this.hideMenu)
    document.addEventListener("scroll", this.hideMenu)

    this.handleEscape = this.handleEscape.bind(this);
    document.addEventListener('keydown', this.handleEscape);
  }

  disconnect() {
    document.removeEventListener("click", this.hideMenu)
    document.removeEventListener("scroll", this.hideMenu)
    document.removeEventListener('keydown', this.handleEscape);
  }

  open(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.componentIdValue) {
      this.prepareMenuForDeckslideComponent(this.componentIdValue)
    } else {
      this.hideMenuOptions()
    }

    this.closeOtherMenus()
    this.positionMenu(event)
    this.menuTarget.classList.remove("hidden")
  }

  prepareMenuForDeckslideComponent(componentId) {
    this.updateLinkTargets(componentId)
    this.showMenuOptions()
  }

  hideMenuOptions() {
    this.toggleMenuOptions(true)
  }

  closeOtherMenus() {
    document.querySelectorAll("#context-menu").forEach((element) => {
      if (element !== this.menuTarget) {
        element.classList.add("hidden")
      }
    })
  }

  showMenuOptions() {
    this.toggleMenuOptions(false)
  }

  updateLinkTargets(componentId) {
    if (this.componentTypeValue === "Element") {
      this.editLinkTarget.href = `/elements/${componentId}/edit`
    } else if(this.componentTypeValue === "Album") {
      this.editLinkTarget.href = `/albums/${componentId}`
    } else if(this.componentTypeValue === "Goal") {
      this.editLinkTarget.href = `/goals/${componentId}/edit`
    } else {
      this.editLinkTarget.href = `#`
    }
    
    this.deleteLinkTarget.href = `/deckslides/${componentId}`
  }

  toggleMenuOptions(hide) {
    this.editLinkTarget.classList.toggle("hidden", hide)
    this.deleteLinkTarget.classList.toggle("hidden", hide)
  }

  positionMenu(event) {
    let menuDimensions = this.getDimensions(this.menuTarget)
    this.menuTarget.style.left = `${this.clampValue(
      event.clientX,
      window.innerWidth,
      menuDimensions.width
    )}px`
    this.menuTarget.style.top = `${this.clampValue(
      event.clientY,
      window.innerHeight,
      menuDimensions.height
    )}px`
  }

  clampValue(value, maxValue, elementDimensions) {
    let viewportDimension = maxValue - elementDimensions
    return value > viewportDimension ? viewportDimension : value
  }

  getDimensions(element) {
    let dimensions = {}
    element.classList.remove("hidden")
    dimensions.width = element.offsetWidth
    dimensions.height = element.offsetHeight
    element.classList.add("hidden")
    return dimensions
  }

  hideMenu(event) {
    if (this.shouldHideMenu(event)) {
      this.menuTarget.classList.add("hidden")
    }
  }

  handleEscape(event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
      // Your action when Escape is pressed, e.g., hide the context menu:
      this.hideMenu(event)
    }
  }

  shouldHideMenu(event) {
    if (!this.hasMenuTarget) {
      return false
    }
    return (
      !this.element.contains(event.target) ||
      event.target === this.menuTarget ||
      event.target.closest("a")
    )
  }

}
