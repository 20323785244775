import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "expand", "feed", "expand", "collapse" ]

  initialize() {
  }

  connect() {
  }

  expand(event) {
    event.preventDefault()
    const feed = this.feedTarget
    const expand = this.expandTarget
    const collapse = this.collapseTarget
    feed.classList.remove("hidden")
    expand.classList.add("hidden")
    collapse.classList.remove("hidden")
  }

  collapse(event) {
    event.preventDefault()
    const feed = this.feedTarget
    const expand = this.expandTarget
    const collapse = this.collapseTarget
    feed.classList.add("hidden")
    expand.classList.remove("hidden")
    collapse.classList.add("hidden")
  }

}