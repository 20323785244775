// app/javascript/controllers/card_table_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["card", "field"];

  connect() {
    this.createSearchInput();
  }

  createSearchInput() {
    // Create the input element
    const input = document.createElement('input');
    input.type = 'search';
    input.placeholder = 'Search records on screen...';
    input.title = 'If records are paginated (ie not all records are visible) then this field will only search/sort the records you see...';
    input.setAttribute('data-action', 'input->tablecards#filterCards');
    input.setAttribute('class', 'bg-white rounded-md border border-sapphire placeholder-gray-300 w-full md:w-1/3 z-30 sticky top-2');
    
    // Insert the input at the beginning of the controller's element
    // Check if the input element already exists
    const existingInput = this.element.querySelector('input[type="search"]');
    if (!existingInput) {
      // Insert the input at the beginning of the controller's element
      this.element.insertBefore(input, this.element.firstChild);
    }
  }

  filterCards(event) {
    // Get the input value
    const sText = event.target.value.toLowerCase();
    // determine if the input value is exclusionary
    const isExclusionary = sText.startsWith('-');
    const searchText = isExclusionary ? sText.slice(1) : sText;
    // Iterate over all cards on the page
    const cards = this.cardTargets;
    
    // Iterate over all rows in the table body
    if (searchText.length === 0) {
      // If the search text is empty, show all rows
      cards.forEach(card => card.style.display = '');
      return;
    }

    cards.forEach(card => {
      // find the div with id of 'name'
      const fields = card.querySelectorAll('[data-tablecards-target="field"]');
      // Check if any field in this card contains the search text
      let matches = false;
      fields.forEach(field => {
        if (matches) {
          return;
        }
        if (field.textContent.toLowerCase().includes(searchText)) {
          matches = true;
        }
        // Show or hide the row based on whether there was a match
        if (isExclusionary) { 
          card.style.display = (matches ? 'none' : '');
         } else {
          card.style.display = (matches ? '' : 'none');
        };
      });
    });
  }
}
