import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    vap: Array,
  }

  initialize() {
    
    if (navigator.serviceWorker) {
      navigator.serviceWorker.register("/service-worker.js", { scope: "/" })
        .then(() => navigator.serviceWorker.ready)
        .then((registration) => {

          // Subscribe to Push Notifications
          window.vapidPublicKey = new Uint8Array(this.vapValue)
          navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
            serviceWorkerRegistration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: window.vapidPublicKey
            }).then((sub) => {
              this.postWebpushSubscription(sub)
            })
          });

          // Register Sync Manager
          if ("SyncManager" in window) {
            registration.sync.register("sync-forms");
          }
        }).then(() => console.log("[Companion]", "Service worker registered!"));
    }
    console.log("PWA Companion Initialized!")
  }

  connect() {
    console.log("PWA Companion Connected!")
  }

  async postWebpushSubscription(sub) {
    const data = await fetch('/webpush_subscriptions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sub),
    }).then(r => r.json())
  }

  

  fetchUsers(text, callback) {
    fetch(`/mentions.json?q=${text}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => callback([]))
  }

}