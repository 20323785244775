import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "icon", "form", "input", "results" ]

  initialize() {
  }

  connect() {
    this.resultsTarget.classList.remove("invisible")
  }

  disconnect(event) {
    try {
      if (event.relatedTarget.id.includes("search_result")) {
        void(0)
      } else {
        this.resultsTarget.classList.add("invisible")
      }
    }
    catch(err) {
      this.resultsTarget.classList.add("invisible")
    }
  }

  toggle() {
    this.iconTarget.classList.add("hidden")
    this.formTarget.classList.remove("invisible")
    this.inputTarget.classList.remove("invisible")
    this.resultsTarget.classList.remove("invisible")
    this.inputTarget.focus()
  }

  clear() {
    this.inputTarget.value = ''
    this.iconTarget.classList.remove("hidden")
    this.formTarget.classList.add("invisible")
    this.inputTarget.classList.add("invisible")
  }

  escape(event) {
    if(event.key === "Escape") {
      this.disconnect()
      this.clear()
    }
  }

  detectSwipe() {
  }
}