import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dragButton", "draggable"]
  static values = { }

  // Variables for dragging
  isDragging = false;
  startX = 0;
  startY = 0;
  originalX = 0;
  originalY = 0;

  connect() {
    // Bind the mouse move and mouse up events
    console.log("Draggable controller connected")

    this.mouseUp = this.mouseUp.bind(this)
    this.mouseDown = this.mouseDown.bind(this)
    this.startDrag = this.startDrag.bind(this)
    this.doDrag = this.doDrag.bind(this)
    this.endDrag = this.endDrag.bind(this)
    this.handleEscape = this.handleEscape.bind(this);
    
    this.draggableTarget.addEventListener('mousedown', this.mouseDown)
                document.addEventListener('mouseup', this.mouseUp)

    document.addEventListener('keydown', this.handleEscape);
  }

  disconnect() {
    console.log("Draggable controller disconnected")
    this.draggableTarget.removeEventListener('mousedown', this.startDrag)
    document.removeEventListener('mousemove', this.doDrag)
    document.removeEventListener('mouseup', this.mouseUp)

    this.draggableTarget.addEventListener('keydown', this.handleEscape);
  }

  startDrag(event) {
    console.log("startDrag")
    this.isDragging = true
    this.lastX = event.clientX
    this.lastY = event.clientY
  }

  doDrag(event) {
    if (!this.isDragging) return

    let deltaX = event.clientX - this.lastX
    let deltaY = event.clientY - this.lastY

    let top = parseInt(this.draggableTarget.style.top || 0)
    let left = parseInt(this.draggableTarget.style.left || 0)

    this.draggableTarget.style.top = `${top + deltaY}px`
    this.draggableTarget.style.left = `${left + deltaX}px`

    this.lastX = event.clientX
    this.lastY = event.clientY
  }

  endDrag(event) {
    console.log("endDrag")
    this.isDragging = false
    this.draggableTarget.removeEventListener('mousemove', this.doDrag)
  }

  mouseDown(event) {
    console.log("mouseDown")
    if (event.button === 0) {
      document.addEventListener('mousemove', this.doDrag)
      this.startDrag(event)
    }
  }

  mouseUp(event) {
    console.log("mouseUp")
    if (event.button === 0) {
      if (this.isDragging) {
        document.removeEventListener('mousemove', this.doDrag)
        this.endDrag(event)
      }
    } else if (event.button === 2) {
      console.log('Right button was released');
    }
  }

  handleEscape(event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
      // Your action when Escape is pressed, e.g., hide the context menu:
      this.endDrag(event)
    }
  }
}
