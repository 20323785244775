import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source","action"];

  copy() {
    const text = this.sourceTarget.innerText
    const textarea = document.createElement("textarea")
    textarea.textContent = text
    document.body.append(textarea)
    textarea.select()
    document.execCommand("copy")
    textarea.remove()
    this.actionTarget.innerText = "Copied!"
    this.actionTarget.classList.add("animate-pulse")
    // wait 2 seconds and revert back to normal
    setTimeout(() => {
      this.actionTarget.innerText = "Copy"
      this.actionTarget.classList.remove("animate-pulse")
    }, 2000)

  }
}